import React, { useCallback, useEffect, useMemo } from 'react'
import { track } from 'analytics'
import { useDevice } from 'device'
import { formatProductLabelsForAnalytics } from 'helpers'
import { useIntl } from 'intl'

import { useCatalogueId, useCatalogueProducts } from 'modules/products'

import type { Money } from 'typings/graphql'

import { useLandingProductsToAddToQueue } from 'containers/LandingContextProvider/LandingContextProvider'
import FetchMoreButtonProductList, { type FetchMoreButtonProductListProps } from 'compositions/productLists/FetchMoreButtonProductList/FetchMoreButtonProductList'

import messages from './messages'


const PLACEMENT = 'Bestsellers'

const RECOMMENDER_NAME = 'PICK_ORDER_BRAND_PARTNERS'

export type ProductListProps = {
  catalogueName: ProductsModule.CatalogueName
  buttonProps: FetchMoreButtonProductListProps['buttonProps']
  isVisible: boolean
  limit?: number
  totalLimit?: number
  offset?: number
  fetchMoreButtonTitle?: FetchMoreButtonProductListProps['fetchMoreButtonTitle']
  fetchMoreButtonStyle?: FetchMoreButtonProductListProps['fetchMoreButtonStyle']
  withDetailedMobileCards?: boolean
  withoutLink?: boolean
  withRetailPrice?: boolean
}

const ProductList: React.FunctionComponent<ProductListProps> = (props) => {
  const {
    catalogueName, buttonProps, isVisible, fetchMoreButtonTitle, fetchMoreButtonStyle,
    withDetailedMobileCards, withoutLink, withRetailPrice = false, limit, offset = 0, totalLimit = 72,
  } = props

  const { isMobile } = useDevice()
  const intl = useIntl()

  const { catalogueId, isFetching: isFetchingCatalogue } = useCatalogueId({ catalogueName })

  const productsLimit = limit || (isMobile ? 12 : 6)

  const variables = useMemo<ProductsModule.UseCatalogueProductsProps['variables']>(() => ({
    id: catalogueId,
    input: {
      limit: productsLimit,
      offset,
      productsFeed: RECOMMENDER_NAME,
    },
    withCount: true,
    withRetailPrice,
  }), [ catalogueId, offset, productsLimit, withRetailPrice ])

  const handleProductLinkClick = (product: ProductFragment.Base, volume: string, price?: Money ) => {
    track('Recommendations product click', {
      isScentbirdSelect: product.theEdit,
      productBrand: product.brandInfo.name,
      productCategory: product.category,
      productFullName: product.fullName,
      productGender: product.gender,
      productId: product.id,
      productLabels: formatProductLabelsForAnalytics({ product, intl }),
      productPrice: (price?.amountCents || 0) / 100,
      productVolume: volume,
      recommenderName: RECOMMENDER_NAME,
    })
  }

  const { data, isFetching, fetchMore } = useCatalogueProducts({
    variables,
    ssr: false,
    notifyOnNetworkStatusChange: true,
    skip: !catalogueId || !isVisible,
  })

  useEffect(() => {
    if (!data) {
      return
    }

    track('Recommendations display', {
      placement: PLACEMENT,
      recommenderName: RECOMMENDER_NAME,
    })
  }, [ data ])

  const handleFetchMore = useCallback(() => {
    track('Show more products click', {
      placement: PLACEMENT,
    })

    void fetchMore({
      variables: {
        ...variables,
        input: {
          ...variables.input,
          offset: data?.products?.length,
        },
      },
    })
  }, [ data?.products?.length, fetchMore, variables ])

  const totalCount = Math.min(data?.count, totalLimit)

  // not important to increase counters
  const promotedProducts = useLandingProductsToAddToQueue()
  const finalProducts = useMemo(() => {
    if (!data?.products || !promotedProducts?.isFetching && !promotedProducts?.products) {
      return null
    }

    return [].concat(promotedProducts?.products, data?.products?.slice(0, -1)).filter(Boolean)
  }, [ data?.products, promotedProducts ])

  return (
    <FetchMoreButtonProductList
      className="mt-24"
      products={finalProducts}
      totalCount={totalCount}
      isFetching={!isVisible || isFetchingCatalogue || isFetching}
      fetchSkeletonsAmount={productsLimit}
      fetchMoreButtonTitle={fetchMoreButtonTitle || messages.buttonTitle}
      fetchMoreButtonStyle={fetchMoreButtonStyle || 'secondary'}
      buttonProps={buttonProps}
      fetchMore={handleFetchMore}
      withoutLink={withoutLink}
      withRetailPrice={withRetailPrice}
      withDetailedMobileCards={withDetailedMobileCards}
      excludeLabels={[ 'BESTSELLER', 'HOLIDAY_SALE', 'PLUS_FREE_GIFT' ]}
      onProductLinkClick={handleProductLinkClick}
    />
  )
}


export default ProductList
